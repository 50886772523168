import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {isPlatformBrowser} from '@angular/common'
import {ConfigService} from '../config/config.service'
import {Logger} from '../logging/logger.types'

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analyticsAreSetup = false

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private readonly configService: ConfigService,
    private readonly logger: Logger,
  ) {}

  setupAnalytics(): void {
    if (this.analyticsAreSetup) {
      this.logger.warn('Analytics are already setup. Did you call `setupAnalytics` more than once.')
      return
    }
    if (isPlatformBrowser(this.platformId)) {
      this.setupGtm()
      this.setupClarity()
      this.setupTealium()
      this.setupMaze()
      this.analyticsAreSetup = true
    }
  }

  sendOfferingSearchEvent(term: string) {
    this.sendSearchEvent(term, 'offering')
  }

  sendPostalCodeSearchEvent(term: string) {
    this.sendSearchEvent(term, 'postalCode')
  }

  sendPlatformSearchEvent(term?: string) {
    if (term) {
      this.sendSearchEvent(term, 'platform-search')
    }
  }

  async hashAndSendEnhancedConversionInfo(clientConversionInfo: ClientConversionInfo) {
    try {
      const window = getNativeWindow()

      const hashedUserInfo: ClientConversionInfo = {
        clientEmail: await this.hashValue(clientConversionInfo.clientEmail),
        ...(clientConversionInfo.clientPhone
          ? {clientEmail: await this.hashValue(clientConversionInfo.clientPhone)}
          : {}),
        ...(clientConversionInfo.clientAddress
          ? {
              clientAddress: {
                clientFirstName: await this.hashValue(clientConversionInfo.clientAddress.clientFirstName),
                clientLastName: await this.hashValue(clientConversionInfo.clientAddress.clientFirstName),
                clientPostalCode: await this.hashValue(clientConversionInfo.clientAddress.clientPostalCode),
                clientCountry: await this.hashValue(clientConversionInfo.clientAddress.clientCountry),
              },
            }
          : {}),
      }

      window.dataLayer.push(hashedUserInfo)
    } catch (e) {
      console.error('Adding data to dataLayer failed.', e.message)
    }
  }

  private sendSearchEvent(term: string, category: string) {
    if (isPlatformBrowser(this.platformId)) {
      if (term && term.length > 0) {
        const window = getNativeWindow()
        window.dataLayer.push({
          event: 'search',
          searchTerm: term,
          searchCategory: category,
        })
      }
    }
  }

  private setupGtm(): void {
    const gtm = 'GTM-WBZDSG2'
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', '${gtm}');
    `
    document.head.appendChild(script)
    this.setupGtagIframe(gtm)
  }

  private setupGtagIframe(gtm: string): void {
    const noscript = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtm}`
    iframe.height = '0'
    iframe.width = '0'
    iframe.style.display = 'none'
    iframe.style.visibility = 'hidden'
    noscript.prepend(iframe)
    document.body.prepend(noscript)
  }

  private setupClarity() {
    const tag = this.configService.isProduction() ? '5z4rfkm4pd' : 'hmyerf2lbd'
    const script = document.createElement('script')
    //Unfortunately Clarity doesn't support Web Workers: https://github.com/microsoft/clarity/issues/368#issuecomment-1495566733
    script.type = 'text/javascript'
    script.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${tag}");
    `
    document.head.appendChild(script)
  }

  private setupTealium() {
    const url = this.configService.isProduction()
      ? '//tags.tiqcdn.com/utag/mobiliar/buildigo/prod/utag.js'
      : '//tags.tiqcdn.com/utag/mobiliar/buildigo/qa/utag.js'
    const script = document.createElement('script')
    script.type = 'text/javascript' // running on external thread produces an error - would be great to fix it
    script.innerHTML = `
       var utag_data = {};
      (function(a,b,c,d){
        a='${url}';
        b=document;c='script';d=b.createElement(c);d.src=a;
        d.type='text/java'+c;d.async=true;
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
      })();
    `
    document.head.appendChild(script)
  }

  private setupMaze() {
    const script = document.createElement('script')
    script.type = 'text/javascript' // run on main thread as maze is UI intensive
    script.innerHTML = `
      (function (m, a, z, e) {
        var s, t;
        try {
          t = m.sessionStorage.getItem('maze-us');
        } catch (err) {}

        if (!t) {
          t = new Date().getTime();
          try {
            m.sessionStorage.setItem('maze-us', t);
          } catch (err) {}
        }

        s = a.createElement('script');
        s.src = z + '?t=' + t + '&apiKey=' + e;
        s.async = true;
        a.getElementsByTagName('head')[0].appendChild(s);
        m.mazeUniversalSnippetApiKey = e;
      })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', 'c5b66f0e-ab79-4d3c-a6ed-bf8c4a6d4a8c');
    `
    document.head.appendChild(script)
  }

  private async hashValue(value: string): Promise<string> {
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(value))
    const hash = Array.from(new Uint8Array(hashBuffer))
    return hash.map(bytes => bytes.toString(16).padStart(2, '0')).join('')
  }
}

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

function getNativeWindow(): Window {
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  // return the global native browser window object
  return window
}

export interface ClientConversionInfo {
  clientEmail: string
  clientPhone?: string
  clientAddress?: {
    clientFirstName: string
    clientLastName: string
    clientPostalCode: string
    clientCountry: string
  }
}
